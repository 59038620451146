import React from 'react';
import SecondaryButton from '../buttons/SecondaryButton';

const optionsData = [
  { text: "CRYPTO" },
  { text: "FOREX" },
  { text: "STOCKS" },
  { text: "INCIDES" }
];

const OptionBox = ({ text }) => (
    <div className="flex items-center justify-center  text-white text-[40px] font-semibold rounded-xl border-gradient-box py-3 px-16">
    {text}
  </div>
);

function TradeOptions() {
  return (
    <div className="mx-auto max-w-7xl text-white py-20 flex flex-col md:flex-row items-start justify-between">
      <div className="md:w-1/2 pr-20">
        <h2 className="text-3xl md:text-5xl font-medium md:leading-[1.25]">
          Trade online with confidence with <span className="text-[#00FFF0]">our platform</span> and easy to use.
        </h2>
        <p className="text-[#A5A3A8]-400 mb-8 text-base md:text-2xl mt-5">
          Experience fair, transparent direct trading on over 200+ instruments with prime trading conditions and robust technology offered by a trusted Forex.
        </p>
        <SecondaryButton label="Explore Now" />
      </div>
      <div className="mt-8 md:mt-0 md:w-1/2 grid grid-cols-2 gap-8">
        {optionsData.map((option, index) => (
          <OptionBox key={index} text={option.text} />
        ))}
      </div>
    </div>
  );
}

export default TradeOptions;
