import React from "react";

function Membership() {
  return (
    <div className="px-4 py-16">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* FREE Plan */}
        <div className="bg-[#10151D] p-6 rounded-lg text-center flex flex-col justify-between h-[500px]">
          <h3 className="text-2xl font-medium text-white mb-4">FREE</h3>
          <div className="text-6xl font-bold text-white">$0</div>
          <ul className="mb-6 mt-24 text-start w-1/2 ml-auto mr-auto">
            <li className="text-white mb-2">• <b>50</b> Crypto signal</li>
            <li className="text-white mb-2">• <b>50</b> Forex signal</li>
            <li className="text-white mb-2">• <b>50</b> Stocks signal</li>
          </ul>
          <div className="flex space-x-4 justify-center mt-auto mx-4 mb-2">
            <button className="bg-teal-400 text-white px-4 py-2 rounded-lg w-1/2">
              Get Started
            </button>
            <button className="text-teal-400 px-4 py-2 rounded-lg w-1/2">
              Contact Us
            </button>
          </div>
        </div>

        {/* BASIC Plan */}
        <div className="bg-[#10151D] p-6 rounded-lg text-center flex flex-col justify-between h-[500px]">
          <h3 className="text-2xl font-medium text-white mb-4">BASIC</h3>
          <div className="text-6xl font-bold text-white">$10</div>
          <div className="text-base mt-2 text-gray-200">$100 annually</div>
          <ul className="mb-6 mt-16 text-start w-1/2 ml-auto mr-auto">
            <li className="text-white mb-2">• <b>500</b> Crypto signal</li>
            <li className="text-white mb-2">• <b>500</b> Forex signal</li>
            <li className="text-white mb-2">• <b>500</b> Stocks signal</li>
          </ul>
          <div className="flex space-x-4 justify-center mt-auto mx-4 mb-2">
            <button className="bg-teal-400 text-white px-4 py-2 rounded-lg w-1/2">
              Get Started
            </button>
            <button className="text-teal-400 px-4 py-2 rounded-lg w-1/2">
              Contact Us
            </button>
          </div>
        </div>

        {/* PREMIUM Plan */}
        <div className="bg-[#10151D] p-6 rounded-lg text-center flex flex-col justify-between h-[500px]">
          <h3 className="text-2xl font-medium text-white mb-4">PREMIUM</h3>
          <div className="text-6xl font-bold text-white">$30</div>
          <div className="text-base mt-2 text-gray-200">$300 annually</div>
          <ul className="mb-6 mt-16 text-start w-2/3 ml-auto mr-auto">
            <li className="text-white mb-2">• <b>Unlimited</b> Crypto signal</li>
            <li className="text-white mb-2">• <b>Unlimited</b> Forex signal</li>
            <li className="text-white mb-2">• <b>Unlimited</b> Stocks signal</li>
            <li className="text-white mb-2">• <b>24/7</b> Bot Trading</li>
          </ul>
          <div className="flex space-x-4 justify-center mt-auto mx-4 mb-2">
            <button className="bg-teal-400 text-white px-4 py-2 rounded-lg w-1/2">
              Get Started
            </button>
            <button className="text-teal-400 px-4 py-2 rounded-lg w-1/2">
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Membership;
