import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardLayout from '../components/Dashboard/DashboardLayout';
import DashboardHome from '../components/Dashboard';
import LatestSignal from '../components/LatestSignal';
import CryptoSignal from '../components/CryptoSignal';
import StockForex from '../components/StockForex';
import API from '../components/API';
import Membership from '../components/Membership';
import Settings from '../components/Settings';

function DashboardPage() {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route index element={<DashboardHome />} />
        <Route path="latest-signal" element={<LatestSignal />} />
        <Route path="crypto-signal" element={<CryptoSignal />} />
        <Route path="stock-forex" element={<StockForex />} />
        <Route path="api" element={<API />} />
        <Route path="membership" element={<Membership />} />
        <Route path="settings" element={<Settings />} />
      </Route>
    </Routes>
  );
}

export default DashboardPage;
