import React from 'react';
import { Link } from 'react-router-dom';

const benefitsArray = [
    {
      imgSrc: "/assets/pngs/time-icon.png",
      altText: "Timely Insights",
      title: "Timely Insights",
      description: "Real-time market monitoring keeps you updated with the latest market movements.",
      linkText: "Get Started",
      linkHref: "#"
    },
    {
      imgSrc: "/assets/pngs/decision-icon.png",
      altText: "Swift Decision-Making",
      title: "Swift Decision-Making",
      description: "React quickly to opportunities and risks with real-time data at your fingertips.",
      linkText: "Get Started",
      linkHref: "#"
    },
    {
      imgSrc: "/assets/pngs/competition-icon.png",
      altText: "Competitive Edge",
      title: "Competitive Edge",
      description: "Stay ahead of the curve and outperform competitors with timely and informed trading decisions.",
      linkText: "Start Now",
      linkHref: "#"
    }
  ];

function Benefits() {
  return (
    <div className="mx-auto max-w-7xl text-white py-16">
      <div className="text-center mb-12">
        <h2 className="text-3xl md:text-5xl font-medium">Why Trade With RMM</h2>
        <p className="text-xl md:text-2xl text-gray-400 mt-2">24/7 Dedicated Support Team</p>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center mt-28 md:space-y-0 md:space-x-8">
         {benefitsArray.map((benefit, index) => (
          <div key={index} className="px-6 text-center w-1/3 h-80 border-gradient">
            <div className="mb-4">
              <img src={benefit.imgSrc} alt={benefit.altText} className="mx-auto w-24 -mt-12" />
            </div>
            <div className='h-[150px]'>
            <h3 className="text-3xl font-bold">{benefit.title}</h3>
            <p className="text-gray-400 mb-4 text-base mt-4">{benefit.description}</p>
            </div>
            <Link to={benefit.linkHref} className="text-base font-semibold text-[#039782] hover:text-green-400">
              {benefit.linkText} &rarr;
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Benefits;
