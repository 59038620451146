import React from "react";
import SecondaryButton from "../buttons/SecondaryButton";

const SecurityInfo = () => {
  return (
    <div className="mx-auto max-w-7xl text-white py-20 flex flex-col gap-20 md:flex-row items-center justify-between">
      <div className="md:w-1/2 mb-8 md:mb-0">
        <h2 className="text-3xl md:text-4xl font-bold mb-4">
          Our platform offers levels of{" "}
          <span className="text-green-500">security</span> to protect your
          funds.
        </h2>
        <p className="text-gray-400 mb-8 text-base md:text-lg">
          Start investing with confidence. Our team is here to provide you with
          the guidance and support you need to make the most of your
          investments.
        </p>
        <SecondaryButton label="Get Started" />
      </div>
      <div className="md:w-1/2 flex justify-center">
        <img
          src="/assets/pngs/security-laptop.png"
          alt="Dashboard and Mobile View"
          className="max-w-full h-auto"
        />
      </div>
    </div>
  );
};

export default SecurityInfo;
