import React from 'react';

const apiLogos = [
  { src: '/assets/pngs/binance.png', alt: 'Binance' },
  { src: '/assets/pngs/coinbase.png', alt: 'Coinbase' },
  { src: '/assets/pngs/huobi.png', alt: 'Huobi Global' },
  { src: '/assets/pngs/kraken.png', alt: 'Kraken' },
  { src: '/assets/pngs/bybit.png', alt: 'Bybit' },
  { src: '/assets/pngs/bit-oasis.png', alt: 'BitOasis' },
];

function API() {
  return (
    <div className="p-4 mt-10">
      <div className="bg-[#10151D] px-32 py-20 rounded-2xl">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-20">
          {apiLogos.map((logo, index) => (
            <div key={index} className="flex justify-center items-center border border-dashed border-gray-500 rounded-2xl h-56">
              <img src={logo.src} alt={logo.alt} className="max-h-28 w-auto object-contain" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default API;
