import React from 'react'

const SecondaryButton = ({label, clickHandler}) => {
  return (
    <button className='border border-white border-solid py-[10px] px-[26px] bg-none text-white text-[20px] hover:bg-[#039782] hover:border-[#039782]' onClick={clickHandler}>
        {label}
    </button>
  )
}

export default SecondaryButton