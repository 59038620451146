import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CurrencyDropDown from "./CurrencyDropDown";
import CryptoDropDown from "./CryptoDropDown";
import ChartTimeButton from "./ChartTimeButton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: [
    "19:00",
    "19:10",
    "19:20",
    "19:30",
    "19:40",
    "19:50",
    "20:00",
    "20:00",
    "20:00",
    "20:00",
  ],
  datasets: [
    {
      label: "Bitcoin/BTC",
      data: [40000, 35000, 30000, 45000, 35000, 40000, 42000, 50000, 47000, 51000, 46000, 47000, 44000, 41000, 45000],
      borderColor: "#3b82f6",
      backgroundColor: "#3b82f6",
      tension: 0.1,
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      mode: "index",
      intersect: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: "#9ca3af",
      },
    },
    y: {
      grid: {
        color: "#374151",
      },
      ticks: {
        color: "#9ca3af",
      },
    },
  },
};

function Chart() {
  return (
    <div className="bg-[#10151D] p-6 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold text-white">Chart</h3>
        <div className="flex items-center space-x-3">
        <img
          src="/assets/svgs/filter.svg"
          alt="fiter icon"
          className="h-9 w-auto cursor-pointer hover:opacity-80"
        />
        <CurrencyDropDown />
        </div>
      </div>
      <div className="flex justify-between mt-8">
        <div>
          <CryptoDropDown />
          <h3 className="text-[#E4E4E4] mt-1 font-bold text-xl">$35,352.02</h3>
        </div>
        <div className="flex items-center space-x-4">
          <ChartTimeButton label={'1h'}/>
          <ChartTimeButton label={'3h'}/>
          <ChartTimeButton label={'1d'}/>
          <ChartTimeButton label={'1w'}/>
          <ChartTimeButton label={'1m'}/> 
        </div>
      </div>
      <div className="h-72 mt-8">
        <Line data={data} options={options} />
      </div>
    </div>
  );
}

export default Chart;
