import React from "react";

const CreditCard = () => {
  return (
    <div className="lg:col-span-1 bg-gradient-to-r from-blue-500 to-green-500 rounded-lg p-6">
      <div className="flex justify-between">
        <h3 className="text-gray-300 text-lg mb-2">Credit Card</h3>
        <img src="/assets/svgs/chip.svg" alt="Visa" className="h-6" />
      </div>
      <p className="text-white text-2xl font-bold mb-2 mt-4">
        3475 7381 3759 4512
      </p>

      <div className="flex justify-between mt-6">
        <p className="text-gray-300 mb-4">DARRELL STEWARD</p>
        <img src="/assets/svgs/visa.svg" alt="Visa" className="h-6" />
      </div>
    </div>
  );
};

export default CreditCard;
