import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

function DashboardLayout() {
  return (
    <div className="flex bg-[#252526] h-screen overflow-hidden">
      <Sidebar />
      <div className="flex-1 flex flex-col h-screen">
        <Navbar />
        <main className="flex-1 overflow-y-auto p-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default DashboardLayout;
