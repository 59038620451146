import React from 'react';

const portfolioItems = [
  {
    name: 'Ethereum',
    value: '$3,245.03',
    amount: '0.12543 ETH',
    change: '-13.40%',
    icon: '/assets/svgs/bitcoin.svg',
    changeColor: 'text-red-500'
  },
  {
    name: 'Bitcoin',
    value: '$3,245.03',
    amount: '0.12543 BTC',
    change: '-6.00%',
    icon: '/assets/svgs/bitcoin.svg',
    changeColor: 'text-red-500'
  },
  {
    name: 'Litecoin',
    value: '$3,245.03',
    amount: '0.12543 LTC',
    change: '+14.25%',
    icon: '/assets/svgs/bitcoin.svg',
    changeColor: 'text-green-500'
  },
  {
    name: 'Solana',
    value: '$3,245.03',
    amount: '0.12543 SOL',
    change: '-2.00%',
    icon: '/assets/svgs/bitcoin.svg',
    changeColor: 'text-red-500'
  },
  {
    name: 'Binance Coin',
    value: '$3,245.03',
    amount: '0.12543 BNB',
    change: '+12.00%',
    icon: '/assets/svgs/bitcoin.svg',
    changeColor: 'text-green-500'
  },
];

function Portfolio() {
  return (
    <div className="bg-[#10151D] rounded-lg p-6">
      <h3 className="text-xl font-semibold text-white mb-4">My Portfolio</h3>
      {portfolioItems.map((item) => (
        <div key={item.name} className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <img src={item.icon} alt={item.name} className="h-11 w-11 mr-4" />
            <div>
              <h4 className="text-lg text-white">{item.name}</h4>
              <p className="text-gray-400">{item.value}</p>
            </div>
          </div>
          <div className="text-right">
            <p className={`text-lg ${item.changeColor}`}>{item.change}</p>
            <p className="text-gray-400">{item.amount}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Portfolio;
