import React from "react";
import { Link, NavLink } from "react-router-dom";

const mainNavItems = [
  {
    to: "/dashboard",
    label: "Dashboard",
    icon: "/assets/svgs/sidebar-icon1.svg",
  },
  {
    to: "/dashboard/crypto-signal",
    label: "Crypto Signal",
    icon: "/assets/svgs/sidebar-icon2.svg",
  },
  {
    to: "/dashboard/latest-signal",
    label: "Latest Signal",
    icon: "/assets/svgs/sidebar-icon3.svg",
  },
  {
    to: "/dashboard/stock-forex",
    label: "Stock/Forex",
    icon: "/assets/svgs/sidebar-icon4.svg",
  },
  {
    to: "/dashboard/api",
    label: "API",
    icon: "/assets/svgs/sidebar-icon5.svg",
  },
  {
    to: "/dashboard/membership",
    label: "Membership Plan",
    icon: "/assets/svgs/sidebar-icon5.svg",
  },
];

const bottomNavItems = [
  {
    to: "/dashboard/settings",
    label: "Setting",
    icon: "/assets/svgs/settings-icon.svg",
  },
  {
    to: "/login", //todo: apply a logout function here
    label: "Logout",
    icon: "/assets/svgs/logout-icon.svg",
  },
];

function Sidebar() {
  return (
    <div className="bg-[#10151D] text-white w-64 p-4 flex flex-col items-center h-screen justify-between">
      <div className="">
        <div className="mb-8">
          <Link to={"/"}>
            <img
              className="h-10 w-auto"
              src="/assets/pngs/logo.png"
              alt="logo"
            />
          </Link>
        </div>
        <nav className="flex flex-col space-y-4 w-full">
          {mainNavItems.map((item) => (
            <NavLink
              key={item.to}
              to={item.to}
              className="hover:bg-[#08B39B] p-2 rounded-md flex items-center"
              activeClassName="bg-gray-800"
            >
              <img className="h-8 w-auto mr-3" src={item.icon} alt="icon" />
              {item.label}
            </NavLink>
          ))}
        </nav>
      </div>
      <div className="flex flex-col space-y-4 w-5/6 mb-8">
        {bottomNavItems.map((item) => (
          <NavLink
            key={item.to}
            to={item.to}
            className="hover:bg-[#08B39B] p-2 rounded-md flex items-center"
            activeClassName="bg-gray-800"
          >
            <img className="h-8 w-auto mr-3" src={item.icon} alt="icon" />
            {item.label}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
