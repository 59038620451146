import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Importing pages
import LoginPage from "./pages/LoginPage";
import LandingPage from "./pages/LandingPage";
import AboutPage from "./pages/AboutPage";
import SolutionsPage from "./pages/SolutionsPage";
import InvestmentPage from "./pages/InvestmentPage";
import DashboardPage from "./pages/DashboardPage";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard/*" element={<DashboardPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/solutions" element={<SolutionsPage />} />
          <Route path="/investment" element={<InvestmentPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
