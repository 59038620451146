import React from "react";

const PromotionInfo = () => {
  return (
    <div className="mx-auto max-w-7xl pt-12 pb-36">
      <div className="bg-[#363638] mx-12 px-16 p-6 text-white rounded-3xl flex gap-8 items-center justify-between space-x-4">
        <div className="w-2/3">
          <h4 className="text-2xl font-medium mb-2">Crypto Exchange</h4>
          <h2 className="text-5xl font-medium mb-4">
            Low fees and deep liquidity
          </h2>
          <p className="text-[#CFCFCF] text-[22px]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis
            aute irure dolor in reprehenderit.
          </p>
        </div>
        <div className="w-1/3 flex justify-end">
          <img
            src="/assets/pngs/exchange-coins.png"
            alt="Crypto Exchange"
            className=""
          />
        </div>
      </div>
    </div>
  );
};

export default PromotionInfo;
