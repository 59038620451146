import React from 'react'

const PrimaryButton = ({label, clickHandler}) => {
  return (
    <button className='py-[10px] px-[26px] border-none bg-[#039782] text-white text-[20px] hover:bg-[#30665e]' onClick={clickHandler}>
        {label}
    </button>
  )
}

export default PrimaryButton