import React from "react";
import SecondaryButton from "../buttons/SecondaryButton";

const dropdownData = [
  { text: "Crypto" },
  { text: "Signal" },
  { text: "Breakout" },
  { text: "Stocks/Forex" },
];

const Dropdown = ({ text, isActive }) => (
  <div
    className={`flex items-center justify-between py-4 px-6 font-semibold text-2xl w-5/6 rounded-lg border border-gray-400 ${
      isActive ? "bg-[#08B098] text-white" : "bg-transparent text-white"
    } mb-2`}
  >
    <span>{text}</span>
    <svg
      className="w-6 h-6"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 9l-7 7-7-7"
      ></path>
    </svg>
  </div>
);

function TradingDropdowns() {
  return (
    <div className="mx-auto max-w-7xl text-white py-16 flex flex-col md:flex-row items-center justify-between gap-28">
      <div className="md:w-1/2 space-y-4">
        {dropdownData.map((item, index) => (
          <Dropdown key={index} text={item.text} isActive={index === 0} />
        ))}
      </div>
      <div className="md:w-1/2 mt-8 md:mt-0 text-center md:text-left">
        <h2 className="text-3xl md:text-5xl font-medium md:leading-[1.2]">
          <span className="text-green-500">Trading</span> Accounts With Prime
          Conditions
        </h2>
        <div className="mt-6">
          <SecondaryButton label="Live Sign Up" clickHandler={() => {}} />
        </div>
      </div>
    </div>
  );
}

export default TradingDropdowns;
