import React from 'react';

const marketData = [
  {
    coin: 'Bitcoin',
    change: '+12.00%',
    marketCap: '$3.560M',
    volume: '$65.20M',
    price: '$48,032.32',
    icon: '/assets/svgs/bitcoin.svg',
    changeColor: 'text-green-500'
  },
  {
    coin: 'Ethereum',
    change: '+17.00%',
    marketCap: '$6.560M',
    volume: '$75.20M',
    price: '$3,032.32',
    icon: '/assets/svgs/bitcoin.svg',
    changeColor: 'text-green-500'
  },
  // Add more market data here as needed
];

function LiveMarket() {
  return (
    <div className="bg-[#10151D] p-6 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold text-white">Live Market</h3>
        <button className="text-[#9E9E9E] px-4 py-2 rounded-md border border-[#31353F] hover:bg-[#3d434e]">View More</button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-left">
          <thead>
            <tr>
              <th className="pb-2 font-medium text-[#9E9E9E]">Coin</th>
              <th className="pb-2 font-medium text-[#9E9E9E]">Change</th>
              <th className="pb-2 font-medium text-[#9E9E9E]">Market Cap</th>
              <th className="pb-2 font-medium text-[#9E9E9E]">24h Volume</th>
              <th className="pb-2 font-medium text-[#9E9E9E]">Price</th>
            </tr>
          </thead>
          <tbody>
            {marketData.map((item, index) => (
              <tr key={index}>
                <td className="py-2 flex items-center text-white">
                  <img src={item.icon} alt={item.coin} className="h-6 w-6 mr-2" />
                  {item.coin}
                </td>
                <td className={`py-2 ${item.changeColor}`}>{item.change}</td>
                <td className="py-2 text-white">{item.marketCap}</td>
                <td className="py-2 text-white">{item.volume}</td>
                <td className="py-2 text-white">{item.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LiveMarket;
