import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-[#373738]">
      <div className="mx-auto max-w-7xl py-8 ">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 gap-14">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <div className="flex items-center">
              <Link to={"/"}>
                <img src="/assets/pngs/logo.png" alt="Logo" className="h-16" />
              </Link>
            </div>
            <p className="text-[#8C8C8C] text-base mt-3">
              Cryptocurrency has revolutionized the world of finance and digital
              transactions, providing individuals with a decentralized and
              secure way to store, transfer, and manage their wealth.
            </p>
            <div className="flex space-x-4 justify-between mt-8">
              <div className="w-full flex justify-between space-x-2">
                <div className="flex gap-3">
                  <img
                    src="/assets/icons/call.png"
                    alt="icon"
                    className="h-12"
                  />
                  <div>
                    <p className="text-[#8C8C8C] text-xs">Have a Question?</p>
                    <p className="text-white text-base">310-437-2766</p>
                  </div>
                </div>

                <div className="flex gap-3">
                  <img
                    src="/assets/icons/mail.png"
                    alt="icon"
                    className="h-12"
                  />
                  <div>
                    <p className="text-[#8C8C8C] text-xs">Contact us at?</p>
                    <p className="text-white text-base">unreal@outlook.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:w-1/2 flex flex-col items-start md:items-end">
            <h3 className="text-xl font-medium mb-4 text-white">Subscribe</h3>
            <p className="text-[#8C8C8C] text-base mb-4">
              Stay up-to-date on discounts, offers and events. Unsubscribe at
              any time.
            </p>
            <div className="flex items-center mb-4 w-full md:w-auto relative">
              <input
                type="email"
                placeholder="Enter your Email"
                className="py-2 px-4 w-full md:w-96 bg-white text-black rounded-full placeholder:text-xs"
              />
              <button className="bg-[#08B098] text-white py-2 px-5 text-[10px] rounded-full hover:bg-green-600 absolute right-1">
                Submit
              </button>
            </div>
            <div className="flex space-x-4 mt-5">
              <img
                src="/assets/icons/facebook.png"
                alt="icon"
                className="h-12"
              />
              <img
                src="/assets/icons/youtube.png"
                alt="icon"
                className="h-12"
              />
              <img src="/assets/icons/ball.png" alt="icon" className="h-12" />
              <img src="/assets/icons/figma.png" alt="icon" className="h-12" />
              <img
                src="/assets/icons/whatsapp.png"
                alt="icon"
                className="h-12"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center text-gray-400 border-t border-gray-700 pt-4">
          <div className="flex space-x-4 mb-4 md:mb-0 text-[#8C8C8C] text-base">
            <Link href="#" className="hover:text-white">
              About Us
            </Link>
            <Link href="#" className="hover:text-white">
              Contact
            </Link>
            <Link href="#" className="hover:text-white">
              Privacy
            </Link>
            <Link href="#" className="hover:text-white">
              Sitemap
            </Link>
            <Link href="#" className="hover:text-white">
              Terms
            </Link>
          </div>
          <div className="text-center md:text-right text-[#8C8C8C] text-base">
            &copy; 2024-2025, All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
