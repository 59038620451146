import React from 'react'
import HeroBanner from '../components/Landing/HeroBanner'
import Benefits from '../components/Landing/Benefits'
import TradeOptions from '../components/Landing/TradeOptions'
import TradingDropdowns from '../components/Landing/TradingDropdowns'
import SecurityInfo from '../components/Landing/SecurityInfo'
import PromotionInfo from '../components/Landing/PromotionInfo'
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";

const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <HeroBanner />
      <Benefits />
      <TradeOptions />
      <TradingDropdowns />
      <SecurityInfo />
      <PromotionInfo />
      <Footer />
    </div>
  )
}

export default LandingPage