import React from "react";
import Card from "./Card";
import Chart from "./Chart";
import Portfolio from "./Portfolio";
import LiveMarket from "./LiveMarket";
import CreditCard from "./CreditCard";

function DashboardHome() {
  return (
    <div className="p-4 grid grid-cols-1 lg:grid-cols-3 gap-4">
      <div className="flex flex-wrap lg:flex-nowrap lg:space-x-4 lg:col-span-3">
        <Card
          title="Bitcoin"
          symbol="BTC"
          value="$52,291"
          change="+0.25%"
          icon="/assets/svgs/bitcoin.svg"
        />
        <Card
          title="Litecoin"
          symbol="LTC"
          value="$8,291"
          change="+0.25%"
          icon="/assets/svgs/bitcoin.svg"
        />
        <Card
          title="Ethereum"
          symbol="ETH"
          value="$28,291"
          change="+0.25%"
          icon="/assets/svgs/bitcoin.svg"
        />
        <Card
          title="Solana"
          symbol="SOL"
          value="$14,291"
          change="-0.25%"
          icon="/assets/svgs/bitcoin.svg"
        />
      </div>

      <div className="lg:col-span-1 space-y-5">
        <CreditCard />
        <Portfolio />
      </div>

      <div className="lg:col-span-2 space-y-5">
        <div className="lg:col-span-2">
          <Chart />
        </div>
        <div className="lg:col-span-1">
          <LiveMarket />
        </div>
      </div>
    </div>
  );
}

export default DashboardHome;
