import React from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

function HeroBanner() {
  return (
    <div className="relative text-white flex flex-col py-52">
      <video 
        src="/assets/videos/hero-video.mp4" 
        className="absolute top-0 left-0 w-full h-full object-cover z-0" 
        autoPlay 
        loop 
        muted 
      />
      <div className="px-24 relative z-10 md:text-left">
        <h1 className="text-[42px] leading-[1.5] font-bold mb-10">
          Experience The Difference With <br/> Real Time Market Monitor.<br/>
          <span className="text-green-500">Trade Smarter</span>, Not Harder.
        </h1>
        {/* <div className="flex space-x-4">
          <PrimaryButton label="Connect Wallet" />
          <SecondaryButton label="Learn More" />
        </div> */}
      </div>
    </div>
  );
}

export default HeroBanner;


