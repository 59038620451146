import React from "react";

function CryptoSignal() {
  const summaryCards = [
    {
      label: "Total Signal",
      value: "1000",
      icon: "/assets/svgs/wallet-fill.svg",
    },
    {
      label: "Long",
      value: "900",
      icon: "/assets/svgs/wallet-usd-fill.svg",
    },
    { label: "Short", value: "100", icon: "/assets/svgs/card.svg" },
    { label: "Profit Booked", value: "950", icon: "/assets/svgs/card.svg" },
    { label: "Stop Loss", value: "50", icon: "/assets/svgs/card.svg" },
  ];

  const liveSignals = [
    {
      token: "Bitcoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$35,000",
      stopLoss: "$34,000",
      targetPrice: "$40,000",
    },
    {
      token: "Ethereum",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$2,000",
      stopLoss: "$1,900",
      targetPrice: "$2,500",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
    {
      token: "Litecoin",
      timeFrame: "15 mins",
      direction: "long",
      status: "profit booked",
      entryPrice: "$150",
      stopLoss: "$140",
      targetPrice: "$180",
    },
  ];

  return (
    <div className="p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-8">
        {summaryCards.map((card, index) => (
          <div
            key={index}
            className="bg-[#10151D] p-4 rounded-lg flex items-center gap-5"
          >
            <div className="bg-[#31353F] rounded-lg p-2">
              <img src={card.icon} alt={card.label} className="h-6 w-6" />
            </div>
            <div>
              <p className="text-[#9E9E9E] text-medium">{card.label}</p>
              <p className="text-white text-3xl font-semibold">{card.value}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="bg-[#10151D] p-4 rounded-2xl lg:w-4/5 w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-white">Live Signals</h3>
          <button className="border border-gray-600 hover:bg-gray-800 text-white px-4 py-2 rounded-lg">
            View More
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full text-white">
            <thead>
              <tr className="text-[#9E9E9E] font-semibold">
                <td className="py-2 px-4 text-left">TOKEN</td>
                <td className="py-2 px-4 text-left">TIME FRAME</td>
                <td className="py-2 px-4 text-left">DIRECTION</td>
                <td className="py-2 px-4 text-left">STATUS</td>
                <td className="py-2 px-4 text-left">ENTRY PRICE</td>
                <td className="py-2 px-4 text-left">STOP LOSS</td>
                <td className="py-2 px-4 text-left">TARGET PRICE</td>
              </tr>
            </thead>
            <tbody>
              {liveSignals.map((signal, index) => (
                <tr key={index}>
                  <td className="py-2 px-4">{signal.token}</td>
                  <td className="py-2 px-4 text-green-500">
                    {signal.timeFrame}
                  </td>
                  <td className="py-2 px-4">{signal.direction}</td>
                  <td className="py-2 px-4">{signal.status}</td>
                  <td className="py-2 px-4">{signal.entryPrice}</td>
                  <td className="py-2 px-4">{signal.stopLoss}</td>
                  <td className="py-2 px-4">{signal.targetPrice}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CryptoSignal;
