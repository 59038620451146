import React from "react";

function Card({ title, symbol, value, change, icon }) {
  return (
    <div className="bg-[#10151D] px-4 py-6 rounded-lg w-1/4 space-y-3">
      <div className="flex">
        <img src={icon} alt="icon" className="h-12 w-10 mr-4" />
        <div>
          <h3 className="text-xl text-white font-semibold">{title}</h3>
          <p className="text-sm text-gray-500">{symbol}</p>
        </div>
      </div>
      <div className="flex justify-between">
        <div>
          <p className="text-2xl text-white">{value}</p>
          <p
            className={`text-sm mt-1 ${
              change.startsWith("+") ? "text-green-500" : "text-red-500"
            }`}
          >
            {change}
          </p>
        </div>
        <div>
          <img src="/assets/pngs/graph.png" alt="graph" className="" />
        </div>
      </div>
    </div>
  );
}

export default Card;
